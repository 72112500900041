@use '@angular/material' as mat;
@include mat.core();

@import 'theme-reset';

.app {
    &.indigo-light {
        @import "skins/indigo-light";
        @include mat.all-component-colors($indigo-light-theme);
        @include theme-reset($indigo-light-theme);
    }

    &.teal-light {
        @import "skins/teal-light";
        @include mat.all-component-colors($teal-light-theme);
        @include theme-reset($teal-light-theme);
    }

    &.red-light {
        @import "skins/red-light";
        @include mat.all-component-colors($red-light-theme);
        @include theme-reset($red-light-theme);
    }

    &.blue-dark {
        @import "skins/blue-dark";
        @include mat.all-component-colors($blue-dark-theme);
        @include theme-reset($blue-dark-theme);
    }

    &.green-dark {
        @import "skins/green-dark";
        @include mat.all-component-colors($green-dark-theme);
        @include theme-reset($green-dark-theme);
    }

    &.pink-dark {
        @import "skins/pink-dark";
        @include mat.all-component-colors($pink-dark-theme);
        @include theme-reset($pink-dark-theme);
    }

    &.gray-dark {
        @import "skins/gray-dark";
        @include mat.all-component-colors($gray-dark-theme);
        @include theme-reset($gray-dark-theme);
    }

    &.gray-light {
        @import "skins/gray-light";
        @include mat.all-component-colors($gray-light-theme);
        @include theme-reset($gray-light-theme);
    }

    &.purple {
        @import "skins/purple";
        @include mat.all-component-colors($purple-light-theme);
        @include theme-reset($purple-light-theme);
    }

    &.yellow {
        @import "skins/yellow";
        @include mat.all-component-colors($yellow-light-theme);
        @include theme-reset($yellow-light-theme);

    }

    &.blue-light {
        @import "skins/blue-light";
        @include mat.all-component-colors($blue-light-theme);
        @include theme-reset($blue-light-theme);
    }

    &.green-light {
        @import "skins/green-light";
        @include mat.all-component-colors($green-light-theme);
        @include theme-reset($green-light-theme);
    }

    &.brown-light {
        @import "skins/brown-light";
        @include mat.all-component-colors($brown-light-theme);
        @include theme-reset($brown-light-theme);
    }

    // &.blue-claire {
    //     @import "skins/blue-claire";
    //     @include mat.all-component-colors($blue-claire-theme);
    //     @include theme-reset($blue-claire-theme);

    // }

    // &.blue-fonce {
    //     @import "skins/blue-fonce";
    //     @include mat.all-component-colors($bleu-fonce-theme);
    //     @include theme-reset($bleu-fonce-theme);

    // }
}