/* perfect-scrollbar */
.ps {
    -ms-touch-action: auto;
    touch-action: auto;
    overflow: hidden !important;
    -ms-overflow-style: none;
    position: relative;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .ps {
        overflow: auto !important;
    }
}

.ps__rail-x {
    display: none;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    -webkit-transition: background-color .2s linear, opacity .2s linear;
    height: 7px;
    bottom: 0px;
    position: absolute;
}

.ps__rail-y {
    display: none;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    -webkit-transition: background-color .2s linear, opacity .2s linear;
    width: 7px;
    right: 0px;
    position: absolute;
    z-index: 99;
}

.ps--active-x>.ps__rail-x,
.ps--active-y>.ps__rail-y {
    display: block;
    background-color: transparent;
}

.ps:hover>.ps__rail-x,
.ps:hover>.ps__rail-y,
.ps--focus>.ps__rail-x,
.ps--focus>.ps__rail-y,
.ps--scrolling-x>.ps__rail-x,
.ps--scrolling-y>.ps__rail-y {
    opacity: 0.6;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus {
    background-color: #eee;
    opacity: 0.9;
}

.ps__thumb-x {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear, height .2s ease-in-out;
    -webkit-transition: background-color .2s linear, height .2s ease-in-out;
    height: 4px;
    bottom: 1px;
    position: absolute;
}

.ps__thumb-y {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear, width .2s ease-in-out;
    -webkit-transition: background-color .2s linear, width .2s ease-in-out;
    width: 4px;
    right: 1px;
    position: absolute;
}

.ps__rail-x:hover>.ps__thumb-x,
.ps__rail-x:focus>.ps__thumb-x {
    background-color: #999;
    height: 7px;
}

.ps__rail-y:hover>.ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y {
    background-color: #999;
    width: 5px;
}

@supports (-ms-overflow-style: none) {
    .ps {
        overflow: auto !important;
    }
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .ps {
        overflow: auto !important;
    }
}

//----------Google Maps---------------
.sebm-google-map-container {
    height: 500px;
    height: calc(100vh - 112px);
}

//----------Leaflet Maps---------------
#leaflet-map {
    width: 100%;
    height: 490px;
    height: calc(100vh - 112px);
}


/* -- NGU carousel -- */
.tile {
    box-shadow: none !important;
}

.nguCarouselPoints {
    list-style-type: none;
    text-align: center;
    padding: 12px;
    margin: 0;
    white-space: nowrap;
    overflow: auto;
    box-sizing: border-box;
}

.nguCarouselPoints li {
    display: inline-block;
    border-radius: 50%;
    background: #6b6b6b;
    padding: 4px;
    margin: 0 3px;
    transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    transition: 0.4s;
}

.nguCarouselPoints li.active {
    background: #6b6b6b;
    transform: scale(1.5);
}

.nguCarouselPoints li:hover {
    cursor: pointer;
}