@import '~@angular/material/theming';


$main-font-family: 'Roboto', sans-serif;

$primary: #096eab;

$top-toolbar-height: 56px;
$horizontal-menu-height: 64px;
$footer-toolbar-height: 56px;

$sidenav-width: 260px;
$compact-sidenav-width: 170px;
$mini-sidenav-width: 66px;