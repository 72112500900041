@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://cdn.quilljs.com/1.2.2/quill.snow.css');
@import url('https://cdn.quilljs.com/1.2.2/quill.bubble.css');

// indigo-pink, deeppurple-amber, purple-green and pink-bluegrey.
@import '@angular/material/prebuilt-themes/indigo-pink.css';

@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "../node_modules/dragula/dist/dragula.min.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/material.css";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import "../node_modules/leaflet/dist/leaflet.css";

@import "app/theme/styles/base";
@import "app/theme/styles/spaces";
@import "app/theme/styles/libs-override";
@import "app/theme/styles/theme";
@import "app/theme/styles/gradients";
@import "app/theme/styles/rtl";

@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700");
@import url("https://fonts.googleapis.com/css?family=Inconsolata:400,700");


$primary: var(--primary-color);
$accent: var(--accent-color);
$warn: var(--warn-color);

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
}


// .mat-icon{
//     color:  $warn;
// }
// .mat-icon-button{
//     color:  $warn;
// }

// .mat-header-cell {
//     font-size: 13px;
//     font-weight: bold;
//     text-align: center;
//     justify-content: space-evenly;
//     background-color: $primary;
//     text-decoration-color: white;
//     // border-color: $accent;
// }

// mat-button{
//     color: $accent !important;
//     background-color: $primary !important ;
// }

// .mat-cell {
//     font-size: 11px;
//     justify-content: center;
//     // width: 200px;
// }

fieldset {
    border-color: $accent;
}

.mat-dialog-content {
    height: 100% !important;
    width: 100% !important;
}



.mat-column-edit {
    flex: 0 0 50px !important;
}